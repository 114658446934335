import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import posthog from 'posthog-js';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const options = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    persistence: 'memory'
}

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, options)

root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>);